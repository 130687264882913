import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from './store';

//清除浏览器默认样式
import "../node_modules/normalize.css/normalize.css";

//自适应rem
import "../node_modules/amfe-flexible/index.js";

//fastclick
import FastClick from "fastclick";
FastClick.attach(document.body);

import "../static/css/reset.css";

// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)
// vant全局按需引入
import {
  Cascader,
  Tab,
  Tabs,
  Field,
  CellGroup,
  DatetimePicker,
  ActionSheet,
  Picker,
  Dialog,
  Popup,
  Toast,
  RadioGroup,
  Radio,
  Overlay,
  Cell
} from "vant";

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Picker);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Cascader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Overlay);
Vue.use(Cell);

Vue.config.productionTip = false;

/*路由守卫*/
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
  window.scrollTo(0, 0);
});

new Vue({
  render: (h) => h(App),
  router,
  // store
}).$mount("#app");
