<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!--不需要缓存组件-->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { record } from "rrweb";
import { getRecordId, sectionUpload } from "./utils/api";
var timer = null;
export default {
  name: "App",
  data() {
    return {
      recordId: "", //回溯id
      content: [], //回溯内容
    };
  },
  async created() {
    if (sessionStorage.getItem("orders") && isNaN(sessionStorage.getItem("orders")) !=true) {
      sessionStorage.setItem("orders", sessionStorage.getItem("orders"));
    } else {
      sessionStorage.setItem("orders", 1);
    }

    if (sessionStorage.getItem("recordId")) {
      this.recordId = sessionStorage.getItem("recordId");
    } else {
      await this.getRecordId();
    }

    await this.getStart();
    await this.sectionUpload(1);
  },
  mounted() {
  },
  beforeMount() {
    console.log(timer);
    let self = this;
    timer = setInterval(async function () {
      await self.sectionUpload(1);
    }, 3 * 1000);
  },
  methods: {
    //分段上传回溯记录
    async sectionUpload(type) {
      if (this.content.length != 0) {
        let orders = sessionStorage.getItem("orders");
        let newOrders = parseInt(orders) + 1;
        sessionStorage.setItem("orders", newOrders);
        let params = {
          content: this.content,
          recordId: this.recordId,
          status: type,
          orders: newOrders,
        };
        this.content = [];
        await sectionUpload(params).then((res) => {
          // console.log(res);
        });
      }
    },
    //获取回溯id
    async getRecordId() {
      await getRecordId().then((res) => {
        this.recordId = res.result;
        sessionStorage.setItem("recordId", res.result);
      });
    },
    //开始录制
    async getStart() {
      let self = this;
      await record({
        emit(event) {
          // 用任意方式存储 event
          self.content.push(event);
        },
      });
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
