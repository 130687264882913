import { GET, POST } from '../utils/http'
import config from '../../constans';
let url = `${config.baseUrl.apiUrl}/insurance/api`

// 产品详情
export const productDetail = (params) => {
  return GET(`${url}/product/productDetailNoLogin`, params)
}

// 权益详情
export const productBenefitDetail = (params) => {
  return GET(`${url}/product/productBenefitDetail`, params)
}

// 获取回溯id
export const getRecordId = () => {
  return GET(`${url}/opRecord/getRecordId`)
}

// 分段上传录屏
export const sectionUpload = (params) => {
  return POST(`${url}/opRecord/sectionUpload`, params)
}


// // 地区信息
// export const postSelectByArea = () => {
//   return POST(`${url}/area/selectByArea`)
// }

// // 职业信息
// export const getSelectOccupationList = () => {
//   return POST(`${url}/occupation/selectOccupationList`)
// }

// // 身份证校验
// export const esignComparison =(params)=> {
//   return POST(`${url}/esign/comparison`,params)
// }

// 投保接口
export const underwrite = (params) => {
  return POST(`${url}/rb/rbInsure`, params)
}

// 查找产品下的险种信息（主+附）

export const selectRisk = (productId) => {
  return POST(`${url}/insure/selectRisk?productId=${productId}`)
}

//获取产品费率表
export const getRateTableFileByProductId = (params) => {
  return GET(`${url}/product/getRateTableFileByProductId`, params)
}
//获取产品条款
export const getProductAttachment = (params) => {
  return GET(`${url}/product/getProductAttachment`, params)
}

//下载pdf
export const download = `${url}/file/getProductFile/`

//获取openId
export const getOpenId = (params) => {
  return GET(`${url}/login/getOpenId`, params)
}

//收银台支付
export const payOrderWxPub = (params) => {
  return GET(`${url}/pay/payOrderWxPub`, params)
}

//获取微信定位jsapi
export const getJsapiTicket = (params) => {
  return GET(`${url}/wx/getJsapiTicket`, params)
}

//获取接种点列表
export const cityList = (params) => {
  return GET(`${url}/rbHealth/vSite/list`, params)
}

//人保试算接口
export const rbCalc = (params) => {
  return POST(`${url}/rb/rbCalc`, params)
}

//人保投保接口
export const rbInsure = (params) => {
  return POST(`${url}/rb/rbInsure`, params)
}

//判断是否支付成功接口
export const paySeeOrderWx = (params) => {
  return POST(`${url}/pay/paySeeOrderWx?channelId=${params.channelId}&orderNo=${params.orderNo}`, params)
}

//承保接口
export const rbUnderwriting = (params) => {
  return POST(`${url}/rb/rbUnderwriting?payWay=${params.payWay}&orderNo=${params.orderNo}&paymentConfirmaDate=${params.paymentConfirmaDate}&recordId=${params.recordId}`, params)
}

//信息查询
export const rbInformation = (params) => {
  return POST(`${url}/rb/rbInformation?orderNo=${params.orderNo}`, params)
}

// =============================
// 投保接口
export const insure = (params) => {
  return POST(`${url}/rbc/insure`, params)
}
// 人保订单列表，默认全部
export const getOrderList = (params) => {
  return GET(`${url}/rbc/order/all?openId=${params.openId}`)
}
// 订单详情
export const getOrderDetail = (params) => {
  return GET(`${url}/rbc/order/details?openId=${params.openId}&id=${params.id}`)
}
// 开具发票接口
export const getInvoice = (params) => {
  return POST(`${url}/rbc/invoice`,params)
}
// 退保保费试算接口
export const calculate = (params) => {
  return POST(`${url}/rbc/surrender/calculate`,params)
}
// 退保接口
export const surrender = (params) => {
  return POST(`${url}/rbc/surrender`,params)
}
// 投保选择医院接口
export const queryHospitals = () => {
  return GET(`${url}/rbc/order/hospitalList`)
}